body{
    background:ghostwhite;
}
body #MusesRadioPlayer-HTML5-player-0{
    position: fixed !important;
    bottom: 0;
    left: 0;
    right: 0;
    background: #252525; 
    border-top: 1px solid #252525;
    /* background: linear-gradient(180deg, #252525 0%, #e2e182   100%);
    background: -moz-linear-gradient(180deg, #252525 0%, #e2e182   100%);
    background: -webkit-linear-gradient(180deg, #252525 0%, #e2e182   100%);
    background: -o-linear-gradient(180deg, #252525 0%, #e2e182   100%);  */
    z-index: 9999;
    width: 270px !important;
    align-content: center;
    border-radius: 0 2em 0 0;
}

body #MusesRadioPlayer-HTML5-player-0 > *{
    /* width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; */
    /* margin: auto; */
    /* position: relative !important; */
}

main{
    min-height: 20em;
    margin-bottom: 2rem;
}
.sc-dVhcbM{
    position: relative !important;
}
.sc-dVhcbM>div{
    background-color: #388e3ccb;
}

.card.card-cascade .view.view-cascade{
    border-radius:.25rem;
    box-shadow:0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}
.card.card-cascade .view.view-cascade.gradient-card-header{
    padding:1.6rem 1rem;
    color:#fff;
    text-align:center
}
.card.card-cascade .view.view-cascade.gradient-card-header .card-header-title{
    font-weight:500
}
.card.card-cascade .view.view-cascade.gradient-card-header .btn-floating{
    background-color:rgba(255,255,255,0.2)
}
.card.card-cascade.wider{
    background-color:transparent;
    box-shadow:none
}
.card.card-cascade.wider .view.view-cascade{
    z-index:2
}
.card.card-cascade.wider .card-body.card-body-cascade{
    z-index:1;
    margin-right:4%;
    margin-left:4%;
    background:#fff;
    border-radius:0 0 .25rem .25rem;
    box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)
}
.card.card-cascade.wider .card-body.card-body-cascade .card-footer{
    margin-right:-1.25rem;margin-left:-1.25rem
}
.card.card-cascade.wider.reverse .card-body.card-body-cascade{
    z-index:3;
    margin-top:-1rem;
    border-radius:.25rem;
    box-shadow:0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}
.card.card-cascade.narrower{
    margin-top:1.25rem
}
.card.card-cascade.narrower .view.view-cascade{
    margin-top:-1.25rem;margin-right:4%;margin-left:4%
}
.card .btn-action{
    margin-top:-1.44rem;margin-bottom:-1.44rem
}

.card .activator {
    position: absolute;
    right: 0;
    font-size: 1.2rem;
}
.card .card-reveal {
    position: absolute;
    top: 100%;
    z-index: 1;
    display: none;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.card .card-reveal .content {
    position: relative;
}
.card.ovf-hidden {
    overflow: hidden;
}
.card .card-share {
    position: relative;
}
.card .card-share .social-reveal {
    position: absolute;
    top: -1.44rem;
    right: 1.88rem;
    width: auto;
    opacity: 0;
    transition: transform 0.35s ease, opacity 0.35s ease;
    transform: translateZ(0);
    transform: translateX(80px);
}
.card .card-share .social-reveal-active {
    z-index: 11;
    opacity: 1;
    transition: transform 0.35s ease, opacity 0.35s ease;
    transform: translateZ(0);
    transform: translateX(-48px);
}
.card .card-reveal .card-title,
.card-wrapper .card-rotating .card-title {
    cursor: pointer;
}
.card .card-reveal .card-title i,
.card-wrapper .card-rotating .card-title i {
    position: absolute;
    right: 0.63rem;
    color: #9e9e9e;
}
.card-wrapper {
    position: relative;
    width: 100%;
    margin: 0;
    perspective: 800px;
}
.card-wrapper .card-up {
    height: 200px;
    overflow: hidden;
}
.card-wrapper .card-up img {
    vertical-align: middle;
}
.card-wrapper .avatar {
    display: block;
    width: 120px;
    margin-top: -60px;
    overflow: hidden;
}
.card-wrapper .avatar img {
    width: 100%;
    background: none repeat scroll 0 0 #fff;
    border: 5px solid #fff;
}
.card-wrapper .card-rotating {
    height: 100%;
    transition: 0.5s;
    transform-style: preserve-3d;
}
.card-wrapper .card-rotating .face {
    position: absolute;
    z-index: 2;
    width: 100%;
    background: #fff;
    backface-visibility: hidden;
}
.card-wrapper .card-rotating.flipped {
    transform: rotateY(180deg);
}
.card-wrapper .card-rotating .front {
    z-index: 1;
}
.card-wrapper .card-rotating .back {
    transform: rotateY(-180deg);
}
.card-wrapper .card-rotating .front,
.card-wrapper .card-rotating .back {
    transition: 1s;
}
.testimonial-card .card-up {
    height: 120px;
    overflow: hidden;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.testimonial-card .avatar {
    width: 120px;
    margin-top: -60px;
    overflow: hidden;
    border: 5px solid #fff;
    border-radius: 50%;
}
.testimonial-card .avatar img {
    width: 100%;
}
.testimonial-card .card-body {
    text-align: center;
}
