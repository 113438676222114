.card-footer .nav-link.active{
    background-color: transparent !important;
    font-weight: bold;
}
.react-photo-gallery--gallery{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: left top;
}
.react-photo-gallery--gallery div{
    align-content: space-between;
    justify-content: space-around;
    align-items: center;
}
.react-photo-gallery--gallery img {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    /* max-width: 100%; */
    width: auto;
    position: relative !important;
    overflow: auto;
}
.tab-content>.tab-pane{
    padding: 1em !important;
}

.facebook-responsive {
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.facebook-responsive iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

.contentHtml img {
    max-width: 100%;
    height:auto;
}