.bg-dark-nav{
    background: #252525;  /* fallback for old browsers */ 
}
.nav-link.active{
    background-color: #33691e !important
}
.navbar.scrolling-navbar.top-nav-collapse{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
@media (max-width: 768px){
    .navbar.scrolling-navbar.top-nav-collapse{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .navbar.scrolling-navbar img{
        width: 0px !important;
    }
    .navbar.scrolling-navbar h1{
        display: block !important;
    }
}