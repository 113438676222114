
#parallax .view {
    min-height: 10vh !important;
    height: unset !important;
    background-position: bottom !important;
  }
.rgba-black-medium{
    background-color: rgba(0,0,0,0.5);
}

