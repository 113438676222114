.react-multi-carousel-item{
    padding: 1.5em;
}
.react-multiple-carousel__arrow{
    background-color: #388e3ccb !important;
}

.custom-justify{
    justify-content: center;
}

@media (max-width: 465px) {
    .custom-justify{
        justify-content: space-between;
    }
}